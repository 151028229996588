







































































import { Component, Vue, Model, Emit, Prop, Watch } from 'vue-property-decorator'
import { AttendanceController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import { calcBg, calcSymbol } from '@/utils/utils'

@Component
export default class StudentDetail extends Vue {
  @Prop() private readonly data!: any
  @Prop() private readonly date!: any
  @Prop() private readonly studentName!: string
  @Prop() private readonly currentSchoolYear!: any

  private moment = moment
  private calcBg = calcBg
  private calcSymbol = calcSymbol

  private get rows(): any {
    const preWeekDay = this.date.isoWeekday()
    let startDate = moment(this.date).subtract(preWeekDay, 'day')
    // let tmpData = {}
    // this.data.forEach(item => {
    //   this.$set(tmpData, moment(item.date).date(), item)
    // })
    const rows = Array.from({ length: 6 }).map((row, ridx) => {
      return Array.from({ length: 7 }).map((item, idx) => {
        const day = startDate.add(1, 'day')
        let status = 'noRecords'
        let leaveInfo = null
        let comment = ''
        if (day.isSame(this.date, 'month')) {
          let data = (this.data || {})[day.date()] || {}
          status = data.status || 'noRecords'
          comment = data.comment
          leaveInfo = data.leaveInfo
        }
        return {
          day: day.date(),
          time: day,
          timeString: day.format('YYYY-MM-DD'),
          type: day.isSame(this.date, 'month')
            ? 'cur'
            : day.isBefore(this.date, 'month')
            ? 'pre'
            : 'next',
          status,
          comment,
          leaveInfo,
        }
      })
    })
    return rows
  }

  private get lastDisabled(): any {
    return (
      moment(this.date).subtract(1, 'month') <
      moment(this.currentSchoolYear.startTime).startOf('month')
    )
  }

  private get nextDisabled(): any {
    return moment(this.date).add(1, 'month') > moment(this.currentSchoolYear.endTime).endOf('month')
  }

  private prevWeek(): void {
    if (this.lastDisabled) return
    this.$emit('weekChange', { type: 'pre' })
  }

  private nextWeek(): void {
    if (this.nextDisabled) return
    this.$emit('weekChange', { type: 'next' })
  }
}
